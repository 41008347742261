export const isApiError = (error) => typeof error === 'object' &&
    error !== null &&
    error.hasOwnProperty('code') &&
    (error.hasOwnProperty('message') || error.hasOwnProperty('cause'));
export const keepError = (watchCodeList) => (error) => {
    if (!isApiError(error)) {
        throw error;
    }
    for (const code of watchCodeList) {
        if (code === error.code) {
            return error;
        }
    }
    throw error;
};
